<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-3">
        <MySidebar active="orders" :user="body.userName" :image="body.image" />
      </div>
      <div class="col-lg-9 col-md-9">
        <div class="left-profile ordersordersorders">
          <div class="orders mb-4">
            <div class="orrddd">
              <h4>{{$t("orders")}}</h4>
              <p>  {{ $t("Your order list data") }}</p>
            </div>
            <button class="btn1-orange"> {{ $t("current requests") }}</button>
            <button class="btn2-gray" @click="$router.push('/ordersDone')">
            {{ $t("Completed orders") }}
            </button>
          </div>

          <div class="m-5 text-center" v-if="list.length == 0">
            <img
              src="../../assets/images/noFavoriteillustration(1).svg"
              class="mt-5"
              alt=""
            />
          </div>
          <div
            v-for="item of list"
            class="current-orders d-flex align-items-center itemorder-phone"
            @click="$router.push('/orderDetails/' + item._id)"
          >
            <img
              :src="$baseUploadURL + item.itemImage"
              style="width: 125px"
              alt=""
            />
            <div class="content w-100">
              <div class="content1 d-flex justify-content-between">
                <h5>
                  {{ $getByLang(item.itemName) }}

                  <span
                    style="color: green"
                    v-if="item.orderStatus == 'delivery'"
                  >
                    {{ $t("Delivery is in progress") }}</span
                  >
                </h5>
                <span
                  >{{$t("Total")}}: <b>{{ Math.round(item.total) }}{{ $t("r.s") }}</b></span
                >
              </div>
              <div class="content2 d-flex justify-content-between">
                <p> {{ $t("The date of application") }}: {{ $formatDate(item.doneDate) }}</p>
                <router-link :to="'/orderDetails/' + item._id">
             {{ $t("Order details") }}
                  <svg
                    class="dg180"
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6.52185 0.257495C6.60116 0.176937 6.69603 0.112681 6.80092 0.0684771C6.90581 0.0242727 7.01863 0.00100503 7.13278 3.18459e-05C7.24694 -0.000941341 7.36015 0.0203994 7.46581 0.0628088C7.57147 0.105218 7.66746 0.167847 7.74819 0.247041C7.82891 0.326235 7.89275 0.420407 7.93598 0.524064C7.97921 0.627721 8.00096 0.738786 7.99997 0.850779C7.99898 0.962772 7.97526 1.07345 7.9302 1.17635C7.88514 1.27926 7.81964 1.37233 7.73753 1.45013L2.68308 6.40878C2.52191 6.56695 2.43136 6.78144 2.43136 7.0051C2.43136 7.22875 2.52191 7.44325 2.68308 7.60142L7.73839 12.5601C7.8996 12.7183 7.99012 12.9329 7.99004 13.1567C7.98996 13.3804 7.89928 13.595 7.73796 13.7531C7.57664 13.9113 7.35788 14.0001 7.12982 14C6.90175 13.9999 6.68306 13.911 6.52185 13.7527L0.251722 7.60142C0.0905442 7.44325 0 7.22875 0 7.0051C0 6.78144 0.0905442 6.56695 0.251722 6.40878L6.52185 0.257495Z"
                      fill="#F59F00"
                      fill-opacity="0.7"
                    />
                  </svg>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      body: {
        userName: null,
        email: null,
        phone: null,
        image: null,
      },
      list: [],
    };
  },
  methods: {},
  async created() {
    this.$http.post("users/getUserById", {}).then((res) => {
      this.body = res.data;
    });
    this.$http.post("cart/getNewMyOrders", {}).then((res) => {
      this.list = res.data.data;
    });
  },
};
</script>

<style></style>
